import { useState, useEffect } from 'react';
import classes from './Home.module.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ElfsightWidget } from 'react-elfsight-widget';
import testPic from '../assets/TestPic-11-2.png';
import newPic from '../assets/newPic.png';
import greenComp from '../assets/pic13.jpeg';
import brittssite from '../assets/brittanyswebsite.JPG';
import trainingpic from '../assets/trainingplatform.JPG';
import Btn from '../components/Button';


const Home = () => {
    const [show, setShow] = useState(false);

    const openHandler = () => setShow(true);
    const closeHandler = () => setShow(false);

useEffect(()=>{
<script src="https://apps.elfsight.com/p/platform.js" defer></script>
},[])



    return <main>
   
<ElfsightWidget widgetID="1efc2993-2db1-4590-a752-be6f2c9fc00d" /> 
<Container fluid>
<Row>
<Col sm={12}>
<div className={classes.content}>
    <h1>Let's Build It By JP - Website Design with Purpose</h1>
    <p className={classes.paragraphs}>Let’s Build It By JP provides website design with purpose and practicality in mind. Websites can be built to look cool and have a lot of neat features, , but what’s the point if no one knows you’re online?</p>
    <p className={classes.paragraphs}>Your website can have amazing photos and look stunning, but if it takes 5 minutes to load, you won’t have a lot of customers tolerating that long load time.</p>
    <p className={classes.paragraphs}>Let’s Build It By JP LLC builds FAST websites with purpose and practicality in mind! Not only that, we build your website at a price you can afford!</p>
</div>
</Col>
</Row>
</Container>
<Container className={classes.whitesmoke} fluid>
    <Row>
        <Col className={classes.midColumn} sm={6}><p className={classes.quote}>"All businesses should have the ability to maintain an online presence regardless of company size or structure"</p>
        <p className={classes.quote} >JP</p>
        <p className={classes.quote}>Owner- Let's Build It By JP LLC</p>
        </Col>
        
        <Col className={classes.midColumn} sm={6}><img className={classes.pic} src={testPic} alt='computer' /></Col>
    </Row>
    <Row>
        <Col sm={12}>
        <a className={classes.anchor} href="tel:6233135230"><Btn className={classes.btn}>Let's Discuss Your Vision</Btn></a>
        </Col>
    </Row>
    <Row>
        <Col sm={6} className={classes.midColumn}>
            <img className={classes.pic} src={newPic} alt='more computers' />
        </Col>
        <Col sm={6} className={classes.midColumn}> 
           <p className={classes.paragraphs}>Custom made solutions to get you online! We use strategic website design to optimize your site for the best organic search results, saving you time and money on advertising, ensuring you stay visible to your customers without breaking the bank!</p> 
        </Col>
    </Row>
</Container>
<Container>
    <Row>
        <Col className={classes.midColumn} sm={6}><img className={classes.pic} src={greenComp} alt='anothercomputerpic' /></Col>
        <Col className={classes.midColumn} sm={6}><h2>AFFORDABLE WEBSITE DESIGN <span className={classes.gray}>WITH PURPOSE!</span></h2></Col>
    </Row>
    <Row>
        <Col sm={12}><ElfsightWidget widgetID='951fa7bd-e050-4d3c-bfce-2c3638a2936d' /></Col>

    </Row>
    <Row>
        <Col>
        <h3 className={classes.boldheader}>Websites are essential just to stay competitive in todays market!</h3>
        </Col>
    </Row>
</Container>
<Container fluid className={classes.whitesmoke}>
    <Row>
        <Col sm={12}>
            <h2 className={classes.boldheader}>Saving You Time Because Time is Money</h2>
            <p className={classes.paragraphs}>Maybe you are starting a new business or the current business is just moving so fast, we understand that it can be difficult to find the time to maintain your own website.
Our advice is “DON’T”!
With affordable annual maintenance plans available, Let’s Build It By JP takes Website Design and Maintenance off your plate.
Our goal is to give you one less thing to worry about. We make website design our business to give you time to focus on YOUR business!</p>
        </Col>
    </Row>
</Container>
<Container>
    <Row>
        <Col sm={12}>
            <h3 className={classes.boldheader}>Projects from Let's Build It By JP</h3>
        </Col>
    </Row>
    <Row>
        <Col className={classes.padding} sm={6}>
            <p className={classes.bottomptags}>Brittany's Craft Box</p>
            <a href="https://brittanyscraftbox.com" target="_blank"><img className={classes.pic} src={brittssite} alt='more computers' /></a>
        </Col>
        <Col className={classes.padding} sm={6}>
            <p className={classes.bottomptags}>Training Platform App</p>
            <a href="https://lms-react-version.web.app" target="_blank"><img className={classes.pic} src={trainingpic} alt='more computers' /></a>
        </Col>
    </Row>
    <Row>
        <Col>
        <h3 className={classes.boldheader}>We'll get your website live so you have one less thing to worry about!</h3>
        </Col>
    </Row>
</Container>
<ElfsightWidget widgetID="00ba2572-0895-4f20-9e2d-da04b627f800" /> 
    </main>
}


export default Home;

