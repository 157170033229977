import React, {useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './About.module.css';
import phoenix from '../assets/phoenix.jpg'
import { ElfsightWidget } from 'react-elfsight-widget';

const About = () => {
    useEffect(()=>{
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
        },[])



    return <>
    <Container fluid className={classes.maincontent}>
    <Row className={classes.spacing}>
        <Col sm={12}>
        <h1>The Story Behind the Design</h1>
    <p className={classes.paragraphs}>JP started the pursuit of a computer science degree while working for a local safety company. It was there that he worked with a lot of small businesses. He saw the challenges these companies struggled with to meet compliance requirements and how large corporations really dominated those markets. Let's Build It By JP LLC was started with the intent to help businesses of any size remain competitive in their respective markets. As a business owner, It shouldn’t have to break the bank to be visible to your customers and it’s the goal of Let's Build It By JP LLC to help ensure that it doesn’t. We are certain there is a solution for every company currently doing business today and we want to help provide those solutions.</p>
    
        </Col>
    </Row>
    <Row>
        <Col sm={6}>
            <h1>Located in the heart of Phoenix</h1>
            <p className={classes.paragraphs}>Serving and located in the heart of the valley!</p>
        </Col>
        <Col sm={6}>
            <img className={classes.pic} src={phoenix} alt='phoenix' />
        </Col>
    </Row>
    <Row>
        <Col sm={12} className={classes.spacing}>
            <h1>Let's Stay In Touch!</h1>
        </Col>
    </Row>
    </Container>
    <Container fluid>
    <Row className={classes.background}>
        
            <Col sm={6}>
                <h1 className={classes.spacing}>Request a Quote</h1>
                <ElfsightWidget widgetId='8102c1e5-472c-489f-923a-efeed08d33a1' />
            </Col>
            <Col sm={6}>
                <h1 className={classes.spacing}>
                    Contact us for more information <br/>
                    Call: <a className={classes.anchor} href="tel:6233135230">623-313-5230</a>
                    </h1>
                    <h3 className={classes.spacing} >Email Us: <a className={classes.anchor} href="mailto:jpowder@letsbuilditbyjp.com">jpowder@letsbuilditbyjp.com</a></h3>
            </Col>

       
    </Row>

    </Container>
    <ElfsightWidget widgetID="00ba2572-0895-4f20-9e2d-da04b627f800" /> 

    </>
}

export default About;