import React, { useEffect, useState } from 'react';
import classes from './Services.module.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ElfsightWidget } from 'react-elfsight-widget';
import pic4 from '../assets/pic4.jpeg';
import vision from '../assets/vision.png';
import build from '../assets/build.png';
import midBanner from '../assets/pic3.jpeg';
import launch from '../assets/launch.png';
import maintenance from '../assets/maintenance.png';
import Btn from '../components/Button';
const Services = () => {
    const [width, setWidth] = useState();

    

    useEffect(()=>{
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
        },[])

  console.log(window.innerWidth)

    return <>
    <Container fluid>
        <Row>
            <Col sm={12} className={classes.banner} >
            </Col>
        </Row>
    </Container>
    <Container>
        <Row>
            <Col sm={6} className={classes.padding}>
                <img className={classes.iconic} src={vision} alt='vision'/>
                <h3 className={classes.center}>Vision</h3>
                <p className={classes.centerptag}>Every website starts with a vision. We'll get an idea of the vision you want for your website and discuss your goals and what you are trying to accomplish with your site</p>
            </Col>
            <Col sm={6} className={classes.padding}>
            <img className={classes.iconic} src={build} alt='vision'/>
                <h3 className={classes.center}>Build</h3>
                <p className={classes.centerptag}>Once we've gathered the details, we'll get starting building your site. Your site will be created within our 2 week guarantee but possibly sooner. We want to establish your online presence as soon as possible.</p>
            </Col>
        </Row>
    </Container>
    <Container fluid>
        <Row>
            <Col sm={12}>
                <img className={classes.midbanner} src={midBanner} alt="banner"/>
            </Col>
        </Row>
    </Container>
    <Container>
        <Row>
            <Col sm={6} className={classes.padding}>
                <img className={classes.iconic} src={launch} alt='vision'/>
                <h3 className={classes.center}>Launch</h3>
                <p className={classes.centerptag}>As soon as we've completed the build, it's time for launch! Don't worry we will work with you throughout the creation of your website and make sure you love your site prior to launch.</p>
            </Col>
            <Col sm={6} className={classes.padding}>
            <img className={classes.iconic} src={maintenance} alt='vision'/>
                <h3 className={classes.center}>Maintenace</h3>
                <p className={classes.centerptag}>All websites need to be maintained as well as optimized to be found by search engines like google and bing. We will keep everything updated on your website including fixing it if it breaks!</p>
            </Col>
        </Row>
    </Container>
    <Container>
        <Row>
           <Col sm={12}>
            <h3 className={classes.boldheader}>Web Design Packages</h3>
            </Col> 
        </Row>
        <Row>
        <Col sm={12}>
        <ElfsightWidget widgetID="3a9af955-30db-4717-a0d9-9d057d4f4339" /><br/>
        </Col> 
        </Row>
    </Container>
    <Container>
        <Row>
            <Col sm={12}>
            <h2 className={classes.boldheader}>
Business Solutions for you
</h2>
<p className={classes.center}> Additional Pages can be added to any package for an additional $100 per page. </p>
<p className={classes.centerptag}>Are you looking for an E-Commerce site or something that hasn’t been listed here?</p>
<p className={classes.centerptag}>Give us a call to discuss additional options</p>
            </Col>
        </Row>
        <Row>
            <Col sm={12} className={classes.padding}>
                <a className={classes.anchor}  href="tel:6233135230"><Btn className={classes.btn}>Call Today</Btn></a>
            </Col>
        </Row>
    </Container>
    <ElfsightWidget widgetId="00ba2572-0895-4f20-9e2d-da04b627f800" /> 

    </>
}

export default Services;