import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import React, { Fragment } from 'react';
import Home from "./pages/Home";
import Services from './pages/Services';
import About from './pages/About';
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import classes from './App.module.css';

function App() {
  
return <Fragment>
<Header className={classes.globalfont}/>
<BrowserRouter>
<Routes>
<Route path='/' element={<Home className={classes.globalfont} />} />
<Route path='/services' element={<Services />} />
<Route path='/aboutus' element={<About />} />
</Routes>
</BrowserRouter>

<Footer className={classes.globalfont}/>
  </Fragment>
}

export default App;
