import { Fragment } from 'react';
import classes from './Header.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

const Header = () => {

    return <Fragment>
        
        <Navbar bg='dark' variant='dark' expand='sm'>
        <Container>
            <Navbar.Brand className={classes.color}>
                Let's Build It By JP
            </Navbar.Brand>
            <Navbar.Toggle  />
            <Navbar.Collapse >
            
            <Nav className={classes.links} >
            <Nav.Link className={classes.color} href='/'>Home</Nav.Link>
            <Nav.Link className={classes.color} href='/Services'>Products</Nav.Link>
            <Nav.Link className={classes.color} href='/aboutus'>About Us</Nav.Link>
            </Nav>
            </Navbar.Collapse>
            </Container>

        </Navbar>
        
    </Fragment>
}

export default Header;
